import React from "react"
import ReactHtmlParser from 'react-html-parser'

import { graphql } from "gatsby"

import Layout from "../parts/layout"
import Seo from "../parts/seo"
import Alternatives from "../parts/alternatives"

const AlternativesPage = ({ data }) => (
  <Layout id="page-alternatives">
    <Seo title="Alternatives" />
    <section>
      <div className="row">
        <div className="page-introduction">{ ReactHtmlParser(data.strapiPracticePage.introduction) }</div>
        <Alternatives alternatives={data.allStrapiAlternative.nodes} />
      </div>
    </section>
  </Layout>
)

export default AlternativesPage

export const pageQuery = graphql`query AlternativesQuery {
  strapiPracticePage {
    introduction
  }
  allStrapiAlternative(sort: {fields: order}) {
    nodes {
      id
      title
      description
      link
      logo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 200, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
      domains {
        slug
        title
      }
      layers {
        slug
        title
      }
    }
  }
}
`